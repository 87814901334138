<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >课程列表</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}课程</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3>课程基本信息</h3>
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="课程名称" prop="courseName">
                  <el-input
                    v-model="ruleForm.courseName"
                    size="small"
                    maxlength="30"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="课程分类" prop="courseType">
                  <el-select
                    v-model="ruleForm.courseType"
                    placeholder="请选择"
                    size="small"
                  >
                    <el-option
                      v-for="item in courseTypeList"
                      :key="item.courseTypeId"
                      :label="item.name"
                      :value="item.courseTypeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="课程封面" prop="CourseCover">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.CourseCover || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item label="课程推广图" prop="promotePath">
                  <el-upload
                    :on-change="handleAvatarSuccess3"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.promotePath || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  label="课程简介"
                  prop="thumbnail"
                  class="bannerBox"
                >
                  <el-upload
                    action
                    :file-list="ruleForm.fileList"
                    list-type="picture-card"
                    :on-change="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload2"
                    :http-request="$requestMine"
                    limit="5"
                    class="df"
                    :class="{ hide: hideUploadCard }"
                    :on-remove="handleRemove"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item
                  label="课程描述"
                  prop="courseDescription"
                  class="courseDescription"
                >
                  <el-input
                    type="textarea"
                    maxlength="255"
                    show-word-limit
                    v-model="ruleForm.courseDescription"
                  ></el-input>
                </el-form-item>
                <h3>课程费用信息</h3>
                <el-form-item label="安卓是否优惠" prop="androidDiscount">
                  <el-radio-group
                    v-model="ruleForm.androidDiscount"
                    @change="AndChange"
                  >
                    <el-radio :label="10">无优惠</el-radio>
                    <el-radio :label="20">限时折扣</el-radio>
                    <el-radio :label="30">限时免费</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="安卓价格(元)" prop="androidPrice">
                  <el-input
                    v-model="ruleForm.androidPrice"
                    v-show="ruleForm.androidDiscount == 10"
                    oninput="value=value.replace(/[^\d.]/g,'')"
                    size="small"
                  ></el-input>
                  <div class="price" v-show="ruleForm.androidDiscount != 10">
                    <div>
                      <span style="width: 4rem">原价:</span>
                      <el-input
                        v-model="ruleForm.androidPrice"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        size="small"
                      ></el-input>
                    </div>
                    <div style="margin-left: 10px">
                      <el-form-item
                        label="折扣价"
                        label-width="5rem"
                        prop="andDiscountPrice"
                        v-if="ruleForm.androidDiscount != 10"
                        oninput="value=value.replace(/[^\d.]/g,'')"
                        class="andDiscountPrice"
                      >
                        <div class="price">
                          <div style="margin-left: 10px">
                            <el-input
                              v-model="ruleForm.andDiscountPrice"
                              :disabled="ruleForm.androidDiscount == 30"
                              oninput="value=value.replace(/[^\d.]/g,'')"
                              size="small"
                            ></el-input>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="IOS是否优惠" prop="iosDiscount">
                  <el-radio-group
                    v-model="ruleForm.iosDiscount"
                    @change="IOSChange"
                  >
                    <el-radio :label="10">无优惠</el-radio>
                    <el-radio :label="20">限时折扣</el-radio>
                    <el-radio :label="30">限时免费</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="IOS价格(元)" prop="iosPrice">
                  <el-select
                    v-model="ruleForm.iosPrice"
                    placeholder="请选择"
                    v-if="ruleForm.iosDiscount == 10"
                    size="small"
                  >
                    <el-option
                      v-for="item in IospriceList"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <div class="price" v-show="ruleForm.iosDiscount != 10">
                    <div>
                      <span style="width: 4rem">原价:</span>
                      <el-select
                        v-if="ruleForm.iosDiscount == 20"
                        v-model="ruleForm.iosPrice"
                        placeholder="请选择"
                        size="small"
                        @change="iosPriceChange"
                      >
                        <el-option
                          v-for="item in IospriceList1"
                          :key="item.label"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-if="ruleForm.iosDiscount == 30"
                        v-model="ruleForm.iosPrice"
                        placeholder="请选择"
                        size="small"
                      >
                        <el-option
                          v-for="item in IospriceList1"
                          :key="item.label"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div style="margin-left: 10px">
                      <div style="margin-left: 10px">
                        <el-form-item
                          label="折扣价"
                          label-width="5rem"
                          prop="iosDiscountPrice"
                          v-if="ruleForm.iosDiscount != 10"
                          class="andDiscountPrice"
                        >
                          <div class="price">
                            <div style="margin-left: 10px">
                              <el-input
                                v-if="ruleForm.iosDiscount == 30"
                                v-model="ruleForm.iosDiscountPrice"
                                :disabled="ruleForm.iosDiscount == 30"
                                oninput="value=value.replace(/[^\d.]/g,'')"
                                size="small"
                              ></el-input>
                              <el-select
                                v-if="ruleForm.iosDiscount == 20"
                                :disabled="ruleForm.iosPrice == ''"
                                v-model="ruleForm.iosDiscountPrice"
                                placeholder="请选择"
                                size="small"
                              >
                                <el-option
                                  v-for="item in iosDiscountPriceList"
                                  :key="item.label"
                                  :label="item.label"
                                  :value="item.value"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="课程有效期" prop="CourseValidity">
                  <el-input
                    v-model="ruleForm.CourseValidity"
                    size="small"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="9"
                  >
                    <template slot="append"><span>天</span></template>
                  </el-input>
                </el-form-item>
              </el-form>
              <h3>课程章节</h3>
              <div style="display: flex; width: 100%" class>
                <div style="width: 100%; margin-right: 25px">
                  <div class="btnbox">
                    <el-button
                      size="mini"
                      class="bgc-bv"
                      style="margin-right: 6px; padding: 10px"
                      round
                      v-if="!data.length"
                      @click="doChooseStyle"
                      >选择目录样式
                    </el-button>
                    <el-button
                      class="bgc-bv"
                      v-show="!data.length"
                      style="margin-right: 16px; padding: 10px"
                      size="mini"
                      round
                      @click="doTreeAppendone"
                      :disabled="disabled"
                      >新增一级项
                    </el-button>
                    <!-- <el-button class="bgc-bv" round @click="dialogVisible = false">快捷添加目录</el-button> 临时隐藏 -->
                  </div>
                  <section class="chapterl-trees createdCourse">
                    <el-tree
                      :data="data"
                      node-key="id"
                      default-expand-all
                      :draggable="draggable"
                      :allow-drop="allowDrop"
                      :allow-drag="allowDrag"
                      v-if="data.length"
                      @node-drop="handleDrop"
                    >
                      <div class="custom-tree-node" slot-scope="{ node, data }">
                        <p class="flexac" v-if="data.level === 1">
                          <span style="width: 44px; text-align: left">
                            <span class="level" style="margin-right: 10px">
                              <!-- 一级 -->
                              {{ data.chapterNum }}
                            </span>
                          </span>
                          <el-input
                            ref="input"
                            size="mini"
                            style="width: 130px"
                            v-if="data.isEdit == 1"
                            @blur="submitEdit(node, data)"
                            @keyup.enter.native="handleEnter($event)"
                            v-model="treeLable"
                            maxlength="30"
                          />
                          <span
                            :title="data.catalogName"
                            class="ellipsis"
                            style="margin-right: 20px"
                            v-else
                            >{{ data.catalogName }}</span
                          >
                          <span class="trees-btns">
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              :disabled="disabled && !data.isEdit"
                              @click.stop="() => doTreeDel(node, data)"
                              >删除</el-button
                            >
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doTreeEdit(node, data)"
                              :disabled="disabled"
                              >重命名</el-button
                            >

                            <el-button
                              :disabled="disabled"
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doAddCourseMore(node, data)"
                            >
                              <uploadAuthArr2
                                :disabled="disabled"
                                @onAddFile="onAddFile2"
                                @fileUploadProgress="uploadFileProgress"
                                @fileUploadSuccess="uploadFileSuccess"
                                ref="batchUploader"
                            /></el-button>
                            <el-button
                              :disabled="disabled"
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => doTreeAppend(node, data)"
                              >新增二级项</el-button
                            >
                          </span>
                        </p>
                        <template v-else-if="data.level === 2">
                          <draggable
                            class="tree-drag-group"
                            :group="{ name: 'people', put: true, pull: false }"
                            :sort="false"
                            @change="
                              (evt) => {
                                nodeChange(evt, data);
                              }
                            "
                            handle=".good"
                            :prevent-on-filter="true"
                          >
                            <span style="width: 60px; text-align: left">
                              <span
                                class="level"
                                v-if="styleType == '1'"
                                style="margin-right: 10px"
                              >
                                <!-- 二级 -->
                                {{ data.chapterNum }}
                              </span>
                              <span
                                class="level"
                                v-else
                                style="margin-right: 10px"
                              >
                                <!-- 一级 -->
                                {{ data.chapterNum }}
                              </span>
                            </span>
                            <el-input
                              ref="input"
                              size="mini"
                              style="width: 120px"
                              v-if="data.isEdit == 1"
                              @blur="submitEdit(node, data)"
                              @keyup.enter.native="handleEnter($event)"
                              v-model="treeLable"
                              maxlength="30"
                            />
                            <span
                              :title="data.catalogName"
                              class="ellipsis"
                              style="margin-right: 3px; width: 203px"
                              v-else
                              >{{ data.catalogName }}</span
                            >
                            <span class="trees-btns">
                              <el-button
                                style="padding: 7px 10px"
                                size="mini"
                                :disabled="disabled && !data.isEdit"
                                @click.stop="() => doTreeDel(node, data)"
                                >删除</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeEdit(node, data)"
                                :disabled="disabled"
                                >重命名</el-button
                              >
                              <!-- <el-button
                                size="mini"
                                style="padding:7px 10px"
                                @click.stop="() => doTreeEdit(node, data)"
                                :disabled="disabled"
                                >上传视频</el-button
                              > -->
                              <el-button
                                :disabled="false"
                                @click.stop="() => doAddCourse(node, data)"
                                size="mini"
                                style="padding: 7px 10px"
                              >
                                <uploadAuthArr3
                                  @onAddFile="onAddFile3"
                                  @fileUploadProgress="uploadFileProgress"
                                  @fileUploadSuccess="uploadFileSuccess"
                                  ref="batchUploader"
                              /></el-button>
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => showVideo(data)"
                                :disabled="disabled"
                                >预览</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeTrysee(node, data)"
                                :disabled="disabled"
                                >{{
                                  data.trySee == false ? "开启试看" : "关闭试看"
                                }}</el-button
                              >
                            </span>
                          </draggable>
                        </template>
                      </div>
                    </el-tree>
                    <div v-else>
                      <p style="padding: 50px; font-size: 14px" v-if="textshow">
                        您还没有目录哦，首先要创建一个目录，你可以
                        <span
                          style="
                            color: #58b5dd;
                            font-size: 14px;
                            cursor: pointer;
                          "
                          @click="doChooseStyle"
                          >选择目录样式</span
                        >
                      </p>
                      <p style="padding: 50px; font-size: 14px" v-else>
                        您可以批量上传视频或逐个上传视频
                      </p>
                    </div>
                  </section>
                  <div
                    class="df"
                    style="
                      margin-right: 16px;
                      padding: 10px;
                      margin-bottom: 20px;
                    "
                  >
                    <el-button
                      v-show="data.length"
                      class="bgc-bv"
                      size="mini"
                      round
                      @click="doTreeAppendone"
                      :disabled="disabled"
                      >新增一级项
                    </el-button>
                    <uploadAuthArr
                      v-show="styleType == 2"
                      @onAddFile="onAddFile"
                      @fileUploadProgress="uploadFileProgress"
                      @fileUploadSuccess="uploadFileSuccess"
                      ref="batchUploader"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              >
              <!-- <el-button v-else class="bgc-bv" @click="doEditSave"
                >确 定</el-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <MenuStyle ref="MenuStyle" @eventBus="styleBack" />
  </div>
</template>
<script>
import draggable from "@/utils/vuedraggable";
import MenuStyle from "@/views/resourse/popup/MenuStyle";
import uploadAuthArr from "@/components/uploadAuthArrkcf";
import uploadAuthArr2 from "@/components/uploadAuthArrkcf2";
import uploadAuthArr3 from "@/components/uploadAuthArrkcf3";
let updataTimer = null;
export default {
  name: "operate/accountEdit",
  components: {
    draggable,
    MenuStyle,
    uploadAuthArr,
    uploadAuthArr2,
    uploadAuthArr3,
  },
  data() {
    return {
      stu: "add",
      courseId: "",
      // 基本信息
      ruleForm: {
        courseName: "", //课程名称
        courseType: "", // 课程分类
        courseDescription: "", // 课程简介
        CourseCover: "",
        CourseCoverfileKey: "",
        promotePath: "",
        CoursePromotionChartfileKey: "",
        androidDiscount: 10,
        iosDiscount: 10,
        androidPrice: "",
        andDiscountPrice: "",
        iosPrice: "",
        iosDiscountPrice: "",
        CourseValidity: "",
        width: "",
        height: "",
        fileList: [],
        srcImg: [],
        thumbnail: [],
        ruleId2: "",
        ruleId1: "",
        sourceId: "",
      },

      rules: {
        courseName: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        courseType: [
          { required: true, message: "请选择课程分类", trigger: "blur" },
        ],
        courseDescription: [
          { required: true, message: "请输入课程描述", trigger: "blur" },
        ],
        CourseCover: [
          {
            required: true,
            message: "请上传课程封面",
            trigger: "change",
          },
        ],
        promotePath: [
          {
            required: true,
            message: "请上传课程推广图",
            trigger: "change",
          },
        ],
        thumbnail: [
          {
            required: true,
            message: "请上传课程简介",
            trigger: "blur",
          },
        ],
        CourseValidity: [
          {
            required: true,
            message: "请输入课程有效期",
            trigger: "blur",
          },
        ],
        androidDiscount: [
          {
            required: true,
            message: "请选择安卓是否有优惠",
            trigger: "blur",
          },
        ],
        androidPrice: [
          {
            required: true,
            message: "请输入安卓原价",
            trigger: "change",
          },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        andDiscountPrice: [
          {
            required: true,
            message: "请输入安卓折扣价",
            trigger: "blur",
          },
          { required: true, validator: this.validatePass6, trigger: "blur" },
        ],
        iosDiscount: [
          {
            required: true,
            message: "请选择IOS是否有优惠",
            trigger: "change",
          },
        ],
        iosPrice: [
          {
            required: true,
            message: "请选择IOS原价",
            trigger: "change",
          },
        ],
        iosDiscountPrice: [
          {
            required: true,
            message: "请选择IOS折扣价",
            trigger: "change",
          },
        ],
      },
      // tree
      hideUploadCard: false,
      draggable: true,
      treeLable: "",
      data: [],
      datas: {},
      defaultProps: {
        children: "children",
        label: "label",
      },
      isSelect: false,
      textshow: true,
      disabled: true,
      styleStu: false,
      addBtnIsOut: false,
      isChapter: false,
      uploadFileList: [],
      courseTypeList: [],
      styleType: 1,
      deletedChapter: [],
      IospriceList: [
        {
          value: 6,
          label: 6,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 68,
          label: 68,
        },
        {
          value: 88,
          label: 88,
        },
        {
          value: 118,
          label: 118,
        },
        {
          value: 168,
          label: 168,
        },
        {
          value: 188,
          label: 188,
        },
        {
          value: 288,
          label: 288,
        },
        {
          value: 328,
          label: 328,
        },
        {
          value: 618,
          label: 618,
        },
      ],
      IospriceList1: [],
      iosDiscountPriceList: [
        {
          value: 6,
          label: 6,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 68,
          label: 68,
        },
        {
          value: 88,
          label: 88,
        },
        {
          value: 118,
          label: 118,
        },
        {
          value: 168,
          label: 168,
        },
        {
          value: 188,
          label: 188,
        },
        {
          value: 288,
          label: 288,
        },
        {
          value: 328,
          label: 328,
        },
        {
          value: 618,
          label: 618,
        },
      ],
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.init();
    if (this.stu == "edit") {
      this.courseId = this.$route.query.courseId;
      this.getDetail(this.courseId);
    }
    let filesStr = localStorage.getItem("uploadedFiles");
    if (filesStr) {
      this.uploadFileList = JSON.parse(filesStr);
    }
  },
  computed: {},
  methods: {
    init() {
      this.getcourseTypeList();
    },
    validatePass6(rule, value, callback) {
      if (value > 9999999) {
        callback(
          new Error("价格必须是0.01~9999999之间的数字，最多保留两位小数。")
        );
      } else {
        callback();
      }
    },
    //获取分类
    getcourseTypeList() {
      this.$post("/biz/ec/course/type/list", {}, 3000, true, 2)
        .then((ret) => {
          this.courseTypeList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    /**
     * 新增
     */
    doAddSave(formName) {
      console.log(this.deletedChapter);
      const params = {
        ecSaleRules: [],
        ecCourseNodes: [],
        ecCourseIntroductions: [],
      };
      params.ecCourse = {
        courseName: this.ruleForm.courseName,
        courseType: this.ruleForm.courseType,
        courseCover: this.ruleForm.CourseCoverfileKey,
        promotePath: this.ruleForm.CoursePromotionChartfileKey,
        courseDescription: this.ruleForm.courseDescription,
      };
      if (this.courseId) {
        params.ecCourse.courseId = this.courseId;
        params.ecCourse.sourceId = this.ruleForm.sourceId;
        params.ecCourse.createTime = this.ruleForm.createTime.replaceAll(
          "/",
          "-"
        );
      }
      params.ecSaleRules.push({
        rulePrice: this.ruleForm.androidPrice,
        ruleSource: "20",
        ruleType: this.ruleForm.androidDiscount,
        validPeriod: this.ruleForm.CourseValidity,
        discountPrice: this.ruleForm.andDiscountPrice,
        ruleId: this.ruleForm.ruleId1,
      });
      params.ecSaleRules.push({
        rulePrice: this.ruleForm.iosPrice,
        ruleSource: "10",
        ruleType: this.ruleForm.iosDiscount,
        validPeriod: this.ruleForm.CourseValidity,
        discountPrice: this.ruleForm.iosDiscountPrice,
        ruleId: this.ruleForm.ruleId2,
      });
      params.ecCourseIntroductions = this.ruleForm.thumbnail;
      if (this.stu == "edit") {
        params.deletedChapter = this.deletedChapter;

        params.isRecommend = this.ruleForm.isRecommend;
        params.ecCourse.isUsable = this.ruleForm.isUsable;
        params.ecCourse.orderNum = this.ruleForm.orderNum;
        params.ecCourse.playNum = this.ruleForm.playNum;
        params.ecCourse.browseNum = this.ruleForm.browseNum;
      }
      if (this.data.length) {
        params.isChapter = this.data[0].section == false ? false : true;
      }
      params.ecCourseNodes = this.data;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit"
              ? "/biz/ec/course/insert"
              : "/biz/ec/course/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 编辑回显
     */
    getDetail(courseId) {
      this.$post(
        "/biz/ec/course/getCourseInfo",
        { courseId },
        3000,
        true,
        2
      ).then((ret) => {
        console.log(ret);
        let ecCourseData = ret.data.ecCourse;
        let ecSaleRulesData = ret.data.ecSaleRules;
        let ecCourseNodes = ret.data.ecCourseNodes;
        let ecCourseIntroductions = ret.data.ecCourseIntroductions;
        this.deletedChapter = ret.data.deletedChapter || [];
        let isChapter = ret.data.isChapter;
        this.isChapter = ret.data.isChapter;
        this.ruleForm = {
          ...this.ruleForm,
          courseName: ecCourseData.courseName, //课程名称
          courseType: ecCourseData.courseType, // 课程分类
          CourseCover: ret.data.courseCoverUrl,
          CourseCoverfileKey: ecCourseData.courseCover,
          promotePath: ret.data.promotePathUrl,
          CoursePromotionChartfileKey: ecCourseData.promotePath,
          CourseValidity: ecSaleRulesData[0].validPeriod,
          thumbnail: ecCourseIntroductions,
          courseDescription: ecCourseData.courseDescription,
          sourceId: ecCourseData.sourceId,
          areaId: ecCourseData.areaId,
          createTime: ecCourseData.createTime,
          isRecommend: ecCourseData.isRecommend,
          isUsable: ecCourseData.isUsable,
          orderNum: ecCourseData.orderNum,
          playNum: ecCourseData.playNum,
          browseNum: ecCourseData.browseNum,
        };
        ecSaleRulesData.map((el) => {
          if (el.ruleSource == "20") {
            this.ruleForm.androidDiscount = Number(el.ruleType);
            this.ruleForm.androidPrice = el.rulePrice;
            this.ruleForm.andDiscountPrice = el.discountPrice;
            this.ruleForm.ruleId1 = el.ruleId;
          } else {
            //  this.ruleForm = {
            this.ruleForm.iosDiscount = Number(el.ruleType);
            this.ruleForm.iosDiscountPrice = el.discountPrice;
            this.ruleForm.iosPrice = el.rulePrice;
            this.ruleForm.ruleId2 = el.ruleId;
            this.IospriceList1 = [
              {
                value: 18,
                label: 18,
              },
              {
                value: 68,
                label: 68,
              },
              {
                value: 88,
                label: 88,
              },
              {
                value: 118,
                label: 118,
              },
              {
                value: 168,
                label: 168,
              },
              {
                value: 188,
                label: 188,
              },
              {
                value: 288,
                label: 288,
              },
              {
                value: 328,
                label: 328,
              },
              {
                value: 618,
                label: 618,
              },
            ];
            //  }
          }
        });

        for (var i = 0; i < ecCourseIntroductions.length; i++) {
          this.ruleForm.fileList.push({
            name: ecCourseIntroductions[i].picPath,
            url: ecCourseIntroductions[i].picPathUrl,
            width: ecCourseIntroductions[i].width,
            height: ecCourseIntroductions[i].height,
          });
        }
        this.disabled = false;
        if (isChapter) {
          this.styleType = 1;
          ecCourseNodes.map((el) => {
            el.level = 1;
            el.children.map((els) => {
              els.level = 2;
            });
            this.data = ecCourseNodes;
          });
        } else {
          this.styleType = 2;
          ecCourseNodes.map((el) => {
            console.log(el);
            let moreCourse = [];
            moreCourse.push({
              ind: new Date().getTime(),
              level: 2,
              catalogName: el.catalogName,
              courseOutlineId: el.courseOutlineId,
              isEdit: "0",
              videoId: el.videoId,
              section: el.section,
              trySee: el.trySee,
            });
            this.data = [...this.data, ...moreCourse];
          });
        }
        this.mapData(this.data);
      });
      console.log(this.styleType);
    },
    // 添加
    doTreeAppendone() {
      this.disabled = true;
      this.styleStu = false;
      this.CreatNameBack();
    },
    doTreeAppend(node, data) {
      this.disabled = true;
      this.datas = data;
      this.styleStu = true;
      this.CreatNameBack();
      this.updataBack2Index();
    },
    /* 试看 */
    doTreeTrysee(node, data) {
      console.log(data);
      if (data.trySee) {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      } else {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      }
      //
    },
    //删除
    doTreeDel(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.indexOf(data);
      if (data.level == 1) {
        let name = "二级";
        if (this.styleType == 2) {
          name = "一级";
        }
        this.$confirm("你确定删除该一级项及下面所有二级项内容?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            children.splice(index, 1);
            this.deletedChapter.push(data.courseOutlineId);
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (data.level == 2) {
        this.$confirm("你确定删除该视频吗?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            children.splice(index, 1);

            this.deletedChapter.push(data.courseOutlineId);
            this.mapData(this.data);
            this.disabled = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
      this.updataBack2Index();
    },
    doTreeEdit(node, data) {
      this.treeLable = data.catalogName;
      this.disabled = true;
      this.$set(node, "isEdit", 1);
      this.$set(data, "isEdit", 1);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    submitEdit(node, data) {
      // treeLable----目录名称
      if (this.treeLable.trim()) {
        this.$set(data, "catalogName", this.treeLable);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treeLable = "";
        this.disabled = false;
      } else {
        this.$message.warning("请输入名称");
      }
    },
    handleEnter(event) {
      if (this.treeLable.trim()) {
        event.target.blur();
      } else {
        this.$message.warning("请输入名称");
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    // 批量上传视频
    courseTableMoreBack(ret) {
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "isEdit", 0);
        this.disabled = false;
      }
      const childBack = [];
      if (!this.addBtnIsOut) {
        console.log("ret", ret);
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          childBack.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            videoId: element.videoId,
            section: false,
            trySee: false,
          });
        }
        this.$set(this.datas, "children", [
          ...this.datas.children,
          ...childBack,
        ]);
      } else {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const moreCourse = [];
          moreCourse.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            isEdit: "0",
            videoId: element.videoId,
            section: false,
            trySee: 0,
            // list: [
            //   {
            //     ind: new Date().getTime(),
            //     name: element.name,
            //     kpointLessonNum: element.kpointLessonNum,
            //     videoId: element.videoId,
            //     progress:element.progress
            //   },
            // ],
          });
          this.data = [...this.data, ...moreCourse];
          console.log(this.data);
        }
      }
      this.mapData(this.data);
    },
    // 单个上传视频
    coursevideoBack(ret) {
      console.log(this.datas);
      console.log(ret);
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 1);
        this.$set(this.datas, "videoId", ret.videoId);
        console.log(this.datas);
        this.treeLable = this.datas.catalogName;
      } else {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 0);
        this.$set(this.datas, "videoId", ret.videoId);
        this.treeLable = this.datas.catalogName;
      }
    },
    // 弹窗回调
    styleBack(ret) {
      this.disabled = true;
      this.styleType = ret;
      console.log(ret);
      if (ret == 1) {
        this.CreatNameBack();
      } else {
        this.textshow = false;
        this.disabled = false;
      }
    },
    // 填写目录名称
    CreatNameBack() {
      this.treeLable = "";
      if (this.styleStu) {
        console.log(444);
        // 样式1下的二级项
        const children = this.datas.children;
        children.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          children: [],
          list: [],
          section: true,
        });
        this.$set(this.datas, "children", children);
        this.styleStu = false;
      } else if (this.styleType == 1) {
        console.log("58447777777");
        //样式1
        this.data.push({
          ind: new Date().getTime(),
          level: 1,
          catalogName: "",
          isEdit: "1",
          section: true,
          children: [],
        });
      } else {
        // 样式2
        this.data.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          section: false,
        });
        // this.disabled = false;
      }
      this.mapData(this.data);
    },
    doChooseStyle() {
      this.$refs.MenuStyle.showPopUp();
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode) {
      console.log("tree drag finish: ", draggingNode);
      this.mapData(this.data);
      this.updataBack2Index();
    },
    //  // 批量添加视频
    doAddCourseMore(node, data) {
      console.log(data);
      this.datas = data;
      // this.onAddFile2(file);
    },
    doAddCourse(node, data) {
      this.datas = data;
      // this.onAddFile3(file);
    },
    onAddFile(file) {
      this.addBtnIsOut = true;
      this.uploadFileList = [];
      this.uploadFileList.push(file);
      this.courseTableMoreBack(this.uploadFileList);
    },
    onAddFile3(file) {
      this.coursevideoBack(file);
    },
    onAddFile2(file) {
      this.addBtnIsOut = false;
      this.uploadFileList = [];
      this.uploadFileList.push(file);
      console.log(this.uploadFileList);
      this.courseTableMoreBack(this.uploadFileList);
    },
    // tree中非同级 不能拖拽
    allowDrop(draggingNode, dropNode, type) {
      const tlevel = dropNode.data.level;
      const plevel = draggingNode.data.level;
      if (plevel === tlevel && type !== "inner") {
        // 同级
        return true;
      } else if (plevel === tlevel + 1 && type === "inner") {
        // 子集
        return true;
      } else {
        return false;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.catalogName.indexOf("三级 3-2-2") === -1;
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
    showVideo(data) {
      console.log(data);
      this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: data.videoId,
        kpointName: data.catalogName,
      });
    },
    /* 课程封面图 */
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.CourseCover = result.data.fileURL || "";
            this.ruleForm.CourseCoverfileKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    /* 课程推广图 */
    //图片上传
    handleAvatarSuccess3(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.promotePath = result.data.fileURL || "";
            this.ruleForm.CoursePromotionChartfileKey =
              result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function (event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          that.ruleForm.width = img.width;
          that.ruleForm.height = img.height;
        };
      };
      reader.readAsDataURL(file);
      return isLt2M;
    },
    /* 上传图片start */
    handlePictureCardPreview(res, fileList) {
      const that = this;
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_WARE");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            console.log(result.data.fileURL);
            that.ruleForm.srcImg.push(result.data.fileURL);
            console.log(that.ruleForm.srcImg);
            this.ruleForm.thumbnail.push({
              width: this.ruleForm.width,
              height: this.ruleForm.height,
              picPath: result.data.fileKey,
            });
            console.log(this.ruleForm.thumbnail);
            // console.log(this.ruleForm.thumbnail);
            this.hideUploadCard =
              this.ruleForm.thumbnail.length == 5 ? true : false;
          })
          .catch(() => {
            return;
          });
      }
    },
    /* 上传图片end */
    /* 删除图片start */
    handleRemove(res, fileList) {
      this.ruleForm.thumbnail = [];
      this.ruleForm.thumbnail.push({
        width: item.width || item.raw.width,
        height: item.height || item.raw.height,
        picPath: item.name,
      });
      this.hideUploadCard = fileList.length == 5 ? true : false;
    },
    /* 删除图片end */
    IOSChange(val) {
      let IospriceList = [];
      if (val == 30) {
        this.ruleForm.iosDiscountPrice = 0;
        this.isSelect = false;
        this.IospriceList1 = this.IospriceList;
      }
      if (val == 20) {
        this.isSelect = true;
        this.ruleForm.iosPrice = "";
        this.ruleForm.iosDiscountPrice = "";
        for (let i = 0; i < this.IospriceList.length; i++) {
          if (this.IospriceList[i].value != 6) {
            this.IospriceList1.push({
              value: this.IospriceList[i].value,
              label: this.IospriceList[i].label,
            });
          }
        }
      }
      if ((val = 10)) {
        this.IospriceList = [
          {
            value: 6,
            label: 6,
          },
          {
            value: 18,
            label: 18,
          },
          {
            value: 68,
            label: 68,
          },
          {
            value: 88,
            label: 88,
          },
          {
            value: 118,
            label: 118,
          },
          {
            value: 168,
            label: 168,
          },
          {
            value: 188,
            label: 188,
          },
          {
            value: 288,
            label: 288,
          },
          {
            value: 328,
            label: 328,
          },
          {
            value: 618,
            label: 618,
          },
        ];
      }
    },
    iosPriceChange(val) {
      console.log(val);
      this.ruleForm.iosDiscountPrice = "";
      this.iosDiscountPriceList = [
        {
          value: 6,
          label: 6,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 68,
          label: 68,
        },
        {
          value: 88,
          label: 88,
        },
        {
          value: 118,
          label: 118,
        },
        {
          value: 168,
          label: 168,
        },
        {
          value: 188,
          label: 188,
        },
        {
          value: 288,
          label: 288,
        },
        {
          value: 328,
          label: 328,
        },
        {
          value: 618,
          label: 618,
        },
      ];
      this.getchange(val);
    },
    getchange(val) {
      let iosDiscountPriceList = [];
      let iosPriceList = [];
      this.IospriceList.map((el, index) => {
        if (el.value < val) {
          iosDiscountPriceList.push({
            value: el.value,
            label: el.label,
          });
        }
      });
      this.iosDiscountPriceList = iosDiscountPriceList;
    },
    AndChange(val) {
      if (val == 30) {
        this.ruleForm.andDiscountPrice = 0;
      }
    },
    updataBack2Index() {
      clearTimeout(updataTimer);
      updataTimer = setTimeout(this.updataIndexReset, 300);
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/CourseManagement/CourseList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
  beforeDestroy: function () {
    if (updataTimer) {
      clearTimeout(updataTimer);
    }
  },
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.password {
  .el-form-item__content {
    line-height: 20px;
  }
}
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  //   width: 45%;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
